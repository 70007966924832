<template lang="pug">
  .gc-user
    a.position-relative#offcanvasMessagesBtn(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasMessages' aria-controls='offcanvasMessages')
      font-awesome-icon(:icon="['fas', 'comments']")
      span.position-absolute.top-0.start-100.translate-middle.p-1.bg-danger.border.border-light.rounded-circle.blink-slow(v-if="hasUnreadMessages")
          span.visually-hidden {{totalUnreadMessages}}
    #offcanvasMessages.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasMessagesLabel')
      .offcanvas-header
        h5#offcanvasMessagesLabel.offcanvas-title(v-if="selectedChatId == 0") Mensajes
        h5#offcanvasMessagesLabel.offcanvas-title.text-primary(v-else) {{selectedChatRoom.account.name}}
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close' @click="setSelectedChatId(0)")
          font-awesome-icon(icon='times')
      .offcanvas-body.p-1(v-if="true")
        .chatv2(style="height:calc(100vh - 20px);position:relative;overflow-y:scroll;overflow-x: hidden")
          chat-room-v2(:reduced="true")
      .offcanvas-body.p-0(v-else)
        template(v-if="selectedChatId == 0")
          chat-room-list(@setSelectedChatId="setSelectedChatId")
        template(v-else)
          chat-room-chat(@setSelectedChatId="setSelectedChatId")
</template>
<script>
  import ChatRoomV2 from "./ChatRoomV2.vue"
  import ChatRoomList from "./ChatRoomsList.vue"
  import ChatRoomChat from "./ChatRoomChat.vue"
  export default {
    name: 'GcMessages',
    components: {
      ChatRoomList,
      ChatRoomChat,
      ChatRoomV2
    },
    data () {
      return {
        filter: '',
        extension: '',
        selectedChatId: 0,
        displayNotification: false
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      chatRooms() {
        return this.$store.state.chatRoomModule.chatRooms
      },
      users() {
        return this.$store.state.userModule.users
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      selectedChatRoomAction() {
        return this.$store.state.chatRoomModule.selectedChatRoomAction
      },
      totalUnreadMessages() {
        let unreads = 0

        this.chatRooms.forEach((cr) => {
          unreads = unreads + cr.unread
        })

        return unreads
      },
      hasUnreadMessages() {

        return this.chatRooms.some((cr) => cr.unread > 0)
      },
      messages() {
        return this.$store.state.chatRoomModule.messages
      },
    },
    watch: {
      selectedChatRoom: {
        deep: true,
        handler(newVal, oldVal) {
          if (!oldVal || (newVal.id && newVal.id != oldVal.id)) {
            this.$store.commit("setMessages", [])
            this.getChatRoomMessages(newVal.id, 1)
          }
        }
      },
      messages() {
        setTimeout(() => { this.scrollToBottom() }, 500)
      },
    },
    mounted() {
      this.$store.dispatch("getUserChatRooms")
      this.$store.dispatch("getUserMessageTemplates", {
        messageTypeId: 1
      })
      this.$store.dispatch("getCurrentUserMessageEnds")
      this.$store.dispatch("getRegisters", {
        moduleUrl: "emoji"
      }).then((res) => {
        this.$store.commit("setEmojis", res)
      })

      window.Echo.channel('App.Company.' + this.currentUser.company_id)
        .listen('NewMessage', (e) => {
            this.processNewMessage(e.message)
        })
        .listen('UpdateMessage', (e) => {
            this.processUpdateMessage(e.message)
        })
        .listen('NewChatRoom', (e) => {
            this.processNewChatRoom(e.chat_room, e.user_id)
        })
        .listen('UpdateChatRoomOwner', (e) => {
            this.processUpdateChatRoomOwner(e.chat_room)
        })
        .listen('UpdateChatRoom', (e) => {
            this.processUpdateChatRoom(e.chat_room)
        })
        .listen('TransferChatRoom', (e) => {
            this.processTransferChatRoom(e.chat_room)
        })
        .listen('CloseChatRoom', (e) => {
            this.processCloseChatRoom(e.chat_room)
        })
    },
    methods: {
      scrollToBottom() {
        let container = this.$el.querySelector(".chatv2");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      },
      getChatRoomMessages(chatRoomId, page) {
        this.$store.dispatch("getChatRoomMessages", {
          chatRoomId: chatRoomId,
          page: page
        })
      },
      setSelectedChatId(id) {
        this.selectedChatId = id

        if(this.selectedChatId > 0) {
          let index = this.chatRooms.findIndex((cr) => cr.id == this.selectedChatId)

          if(index > -1) {
            this.$store.commit("setSelectedChatRoom", this.chatRooms[index])
          } else {
            this.$store.commit("setSelectedChatRoom", {})
          }
        } else {
          this.$store.commit("setSelectedChatRoom", {})
        }
      },
      processNewMessage(message) {
        let index = this.chatRooms.findIndex((cr) => cr.id == message.chat_room_id)
        if(index > -1) {
            let chatRoom = this.chatRooms[index]
            this.$set(chatRoom, "last_message_at", message.sended_at)
            this.$store.commit("updateChatRoom", chatRoom)
        }
        
        if (this.selectedChatRoom.id && this.selectedChatRoom.id == message.chat_room_id && this.selectedChatRoomAction == 'chat') {
console.log("test if");
          this.$store.commit("addMessage", message)
          this.$store.dispatch("chatRoomUserMarkAsRead", {chatRoomId: message.chat_room_id})

          //RC: Si el mensaje es de la cuenta tenemosq ue actualizar el parametro last_account_message
          if(!message.user_id) {
            let chatRoom = this.selectedChatRoom

            this.$set(chatRoom, 'last_account_message', message.sended_at)
            this.$store.commit("setSelectedChatRoom", chatRoom)
            console.log(chatRoom)
          }
        } else {
console.log("test else")
          if(index > -1) {
console.log("index > -1");
            let chatRoom = this.chatRooms[index]
            /*let ownerOnline = true

            if (chatRoom.owner_user_id) {
              let indexUser = this.users.findIndex((u) => u.id == chatRoom.owner_user_id)

              if (indexUser == -1 || !this.users[indexUser].active_user_session) {
                ownerOnline = false
              }
            }*/

            this.$set(chatRoom, "last_message_at", message.sended_at)
            if(!chatRoom.owner_user_id || this.currentUser.id == chatRoom.owner_user_id) {
console.log("(!chatRoom.owner_user_id || this.currentUser.id == chatRoom.owner_user_id)");
              this.$set(chatRoom, "unread", chatRoom.unread + 1)
console.log("message.user_id " + message.user_id); 
              if (!message.user_id) {
                this.$set(chatRoom, "last_account_message", message.sended_at)
               console.log("test"); 
                if(!this.displayNotification) {
                  this.displayNotification = true
                  let options = {
                    body: "Nueva Mensaje " + message.account.name,
                    requireInteraction: true,
                    icon: "/media/whatsapp.png"
                  }

                  if(document.hasFocus) {
                    options = {
                      body: "Nueva Mensaje " + message.account.name,
                      requireInteraction: false,
                      icon: "/media/whatsapp.png"
                    }
                    this.displayNotification = false
                  }

                  let n = new Notification("Nueva Mensaje", options);
                  n.onclick = ((event) => {
                    this.displayNotification = false
                    event.preventDefault();
                    window.focus()
                    n.close()
                  })
                  n.onclose = (() => {
                    this.displayNotification = false
                  })
                }
              }
            }

            this.$store.commit("updateChatRoom", chatRoom)
          }
        }
      },
      processUpdateMessage(message) {
        //let index = this.chatRooms.findIndex((cr) => cr.id == message.chat_room_id)
        
        if(this.selectedChatRoom.id && this.selectedChatRoom.id == message.chat_room_id) {
          this.$store.commit("updateMessage", message)
          this.$store.dispatch("chatRoomUserMarkAsRead", {chatRoomId: message.chat_room_id})
        }
      },
      processNewChatRoom(chatRoom, userId) {
        if(this.currentUser.id == userId) {
          this.$store.commit("addChatRoom", chatRoom)
        }
      },
      processUpdateChatRoomOwner(chatRoom) {
        let index = this.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

        if(index > -1) {
          let chatRoomStore = this.chatRooms[index]

          this.$set(chatRoomStore, 'owner_user_id', chatRoom.owner_user_id)
          this.$set(chatRoomStore, 'owner_user', chatRoom.owner_user)

          if(chatRoom.owner_user_id && this.currentUser.id != chatRoom.owner_user_id) {
            this.$set(chatRoomStore, 'unread', 0)
          }

          this.$store.commit("updateChatRoom", chatRoomStore)

        }
      },
      processUpdateChatRoom(chatRoom) {
        let index = this.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

        if(index > -1) {
          this.$store.commit("updateChatRoom", chatRoom)

        }
      },
      processTransferChatRoom(chatRoom) {
        let index = this.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

        if(index > -1) {
          let chatRoomStore = this.chatRooms[index]

          this.$set(chatRoomStore, 'owner_user_id', chatRoom.owner_user_id)
          this.$set(chatRoomStore, 'owner_user', chatRoom.owner_user)

          if(chatRoom.owner_user_id && this.currentUser.id != chatRoom.owner_user_id) {
            this.$set(chatRoomStore, 'unread', 0)
          } else if(chatRoom.owner_user_id && this.currentUser.id == chatRoom.owner_user_id) {
            this.$set(chatRoomStore, 'transfer', true)
          }

          this.$store.commit("updateChatRoom", chatRoomStore)

        } else if(chatRoom.owner_user_id == this.currentUser.id) {
          this.$set(chatRoom, 'transfer', true)
          this.$store.commit("addChatRoom", chatRoom)
        }

        if(chatRoom.owner_user_id == this.currentUser.id) {
          let options = {
              body: "Chat de " + chatRoom.account.name,
              requireInteraction: false
          }

          let n = new Notification("Chat Transferido", options);
          n.onclick = ((event) => {
              event.preventDefault();
              window.focus()
          })
          n.onclose = (() => {
          })
        }
      },
      processCloseChatRoom(chatRoom) {
        
        let index = this.chatRooms.findIndex((cr) => cr.id == chatRoom.id)

        if(index > -1) {
          this.$store.commit("spliceChatRoom", index)

        }
      }
      
    }
  }
</script>
<style lang="scss" scoped>

</style>
